import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";
import SwiperContainer from "./SwiperContainer";

const SwiperOuterWrapper = styled.div`
  position: relative;
  overflow: visible;
  margin: ${pxToRem(20)} 0;

  ${({ stretch }) =>
    stretch &&
    css`
      .swiper-slide {
        display: flex;
        height: auto;
      }
    `}

  ${({ resetMargin }) =>
    resetMargin &&
    css`
      margin: 0;

      ${SwiperContainer} {
        margin: 0;
      }
    `};
  
  .swiper-pagination-bullets {
    display: block;

    ${({ pagination }) =>
    pagination.bottom &&
    css`
        bottom: ${pagination.bottom}px;
      `}

      ${({ pagination }) =>
    pagination.bottomDesktop &&
    css`
          ${mq.md(css`
            bottom: ${pagination.bottomDesktop}px;
          `)}
        `}

    ${({ pagination }) =>
    pagination.position === "top" &&
    css`
        top: -30px;
      `};

    ${mq.lg(css`
      display: none;
    `)};

    ${({ pagination }) =>
    pagination.visibleOnDesktop &&
    css`
        ${mq.lg(css`
          display: block;
        `)}
      `}
  }

  .swiper-pagination-bullet {
    background: ${({ pagination }) => pagination.color};
    opacity: 0.7;
    transform: scale(0.6);
    margin: 0 2px;
  }

  ${({ pagination }) =>
    pagination &&
    pagination.bulletSize &&
    css`
      .swiper-pagination-bullet {
        width: ${pagination.bulletSize}px;
        height: ${pagination.bulletSize}px;
        margin: 0
          ${pagination.bulletSpaceBetween ? pagination.bulletSpaceBetween : 0}px;
      }
    `}


  .swiper-pagination-bullet-active {
    background: ${({ pagination }) => pagination.color};
    opacity: 0.7;
    transform: scale(1.2);
    transition: transform 300ms;
  }

  .swiper-pagination {
    width: 100%;
    margin: 10px 0;
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-image: none;
    margin-top: 0;
    transform: translateY(-50%);
    height: auto;
    fill: ${({ navigation }) =>
    navigation && navigation.color ? navigation.color : "#ffffff"};
    display: none;

    ${mq.lg(css`
      display: block;
      width: ${pxToRem(20)};
    `)}
  }

  .swiper-button-prev {
    left: ${({ navigation }) =>
    navigation && navigation.left ? pxToRem(navigation.left) : 0};
    width: ${({ navigation }) =>
    navigation && navigation.width ? pxToRem(navigation.width) : pxToRem(20)};
  }

  .swiper-button-next {
    right: ${({ navigation }) =>
    navigation && navigation.right ? pxToRem(navigation.right) : 0};
    width: ${({ navigation }) =>
    navigation && navigation.width ? pxToRem(navigation.width) : pxToRem(20)};
  }

  .swiper-button-lock {
    display: none;
  }
`;

export default SwiperOuterWrapper;
