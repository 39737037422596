import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";

const SwiperContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: ${pxToRem(20)} 0;

  ${({ mobileWidth }) =>
    mobileWidth &&
    css`
      width: ${mobileWidth}%;

      ${mq.lg(css`
        width: 100%;
      `)}
    `}

    ${({ customWidth, left }) =>
    customWidth &&
    css`
        width: ${customWidth}%;
        margin: ${left ? "0" : "0 auto"};
      `}

  ${({ viewOverflow }) =>
    viewOverflow &&
    css`
      overflow: visible;
    `};
`;

export default SwiperContainer;
