import React, { useEffect, useState, useRef } from "react";
import SVG from "react-inlinesvg";
import SwiperJS from "swiper";
import { breakpoints } from "@/lib/mediaQueries";
import SwiperContainer from "./components/SwiperContainer";
import SwiperOuterWrapper from "./components/SwiperOuterWrapper";

const Swiper = ({
  items,
  settings,
  component: Component,
  onClick,
  onSlideChange,
  onMouseEnter,
  onMouseLeave,
  width,
  mobileWidth,
  left,
  resetMargin,
  style,
  containerStyle,
  wrapperStyle
}) => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  let swiperRef = useRef();

  useEffect(() => {
    if (swiperInstance && items) {
      swiperInstance.update();
    }
  }, [items]);

  useEffect(() => {
    const slider = swiperRef.current.querySelector(".slider");
    const pagination = swiperRef.current.querySelector(".swiper-pagination");
    const nextEl = swiperRef.current.querySelector(".swiper-button-next");
    const prevEl = swiperRef.current.querySelector(".swiper-button-prev");

    let swiperSettings = {
      direction: "horizontal",
      initialSlide: 0,
      slidesPerView: 2,
      breakpoints: {
        [breakpoints.sm]: {
          centeredSlides: false,
          slidesPerView: 1
        },
        [breakpoints.md]: {
          slidesPerView: 2
        },
        [breakpoints.lg]: {
          slidesPerView: 3
        }
      },
      pagination: {
        el: pagination,
        type: "bullets",
        clickable: true
      },
      navigation: {
        nextEl,
        prevEl
      }
    };

    if (settings) {
      swiperSettings = Object.assign(swiperSettings, settings);
    }

    const newSwiper = new SwiperJS(slider, swiperSettings);

    newSwiper.on("slideChange", () => {
      onSlideChange(newSwiper.activeIndex);
    });

    setSwiperInstance(newSwiper);

    return () => {
      newSwiper.destroy();
    };
  }, []);

  return (
    <SwiperOuterWrapper
      ref={swiperRef}
      stretch={!!settings.stretch}
      pagination={settings.paginationSettings}
      navigation={settings.navigationSettings}
      resetMargin={resetMargin}
      style={style}
    >
      <SwiperContainer
        style={containerStyle}
        customWidth={width}
        mobileWidth={mobileWidth}
        left={left}
        viewOverflow={settings.overflow}
        className="slider"
      >
        <div className="swiper-wrapper" style={wrapperStyle}>
          {items &&
            items.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`swiper-slide slide-${index}`}
                  onClick={() => {
                    onClick(item, index);
                    if (swiperInstance) {
                      swiperInstance.slideTo(index);
                    }
                  }}
                  onMouseEnter={() => {
                    onMouseEnter(item, index);
                  }}
                  onMouseLeave={() => onMouseLeave(item, index)}
                >
                  {React.createElement(Component, item)}
                </div>
              );
            })}
        </div>
      </SwiperContainer>
      {settings.showPagination && <div className="swiper-pagination" />}
      {settings.showNavigation && [
        <div key="prev" className="swiper-button-prev">
          <SVG src="/static/svg/chevron-left.svg" />
        </div>,
        <div key="next" className="swiper-button-next">
          <SVG src="/static/svg/chevron-right.svg" />
        </div>
      ]}
    </SwiperOuterWrapper>
  );
};

Swiper.defaultProps = {
  onClick: () => null,
  onMouseEnter: () => null,
  onMouseLeave: () => null,
  onSlideChange: () => null,
  settings: {
    watchOverflow: true,
    showNavigation: true,
    showPagination: true,
    paginationSettings: {
      color: "#d8d8d8"
    },
    navigationSettings: {
      color: "#d8d8d8"
    }
  }
};

export default Swiper;
